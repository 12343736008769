<template>
  <div>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="30">
        <el-col :span="8">
          <el-form-item label="Prefix">
            <el-input
              placeholder="Prefix"
              v-model="field.auto_increment_prefix"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item label="Choose your preferences">
            <span class="red-asterisk">*</span>
            <br />
            <el-checkbox-group v-model="field.selected_preferences">
              <div class="checkbox-container">
                <el-checkbox
                  v-for="option in availableOptions"
                  :label="option"
                  :key="option"
                  >{{ option }}</el-checkbox
                >
              </div>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Length of Characters">
            <el-input-number
              type="number"
              :min="getMinValue()"
              v-model="field.random_text_length"
              placeholder="No of characters"
            >
            </el-input-number>
          </el-form-item>
          <el-row>
            <el-col :span="24">
              <el-checkbox v-model="field.properties.allow_to_regenerate"
                >Allow to regenerate</el-checkbox
              >
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="30">
            <el-col :span="24">
              <is-field-required :field="field" class="field-required" />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "templates-formComponents-SingleLineText",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
  },

  props: ["field", "fieldsData"],
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    // allGlobalVariables() {
    //   return this.getAllGlobalVariables
    //     ? this.getAllGlobalVariables.data || []
    //     : [];
    // },
  },
  mounted() {
    console.log("random text", this.field);
    // this.fetchGlobalVaribales();
  },
  data() {
    return {
      checkAll: false,
      availableOptions: [
        "Use Letters",
        "Use non capitalized",
        "Use Numbers",
        "Use Special Characters",
      ],
    };
  },
  methods: {
    // async fetchGlobalVaribales() {
    //   let params = {
    //     get_all: true,
    //     input_type: this.field.input_type,
    //   };

    //   await this.$store.dispatch(
    //     "globalVariables/fetchGlobalVariables",
    //     params
    //   );
    // },
    // setGlobalVariable() {
    //   let globalVariable = this.allGlobalVariables.find(
    //     (x) => x._id == this.field.global_variable_id
    //   );
    //   this.field.label = globalVariable.label;
    //   this.field.description = globalVariable.description;
    //   this.field.options = globalVariable.options;
    // },
    getMinValue() {
      let min = 5;
      switch (this.field.selected_preferences.length) {
        case 1:
          min = 8;
          break;
        case 2:
          min = 6;
          break;
      }
      this.field.random_text_length = min;
      return min;
    },
  },
};
</script>

<style lang="scss">
.checkbox-container {
  display: flex;
  flex-direction: column;
}

.el-checkbox {
  align-items: center;
}
</style>
